import React, { ReactElement } from 'react';

export const CellWithHoverActions: React.FC<{
  cell: string | ReactElement;
}> = ({ cell, children }) => {
  return (
    <>
      <div className='cell'>{cell}</div>
      <div className='hover'>{children}</div>
    </>
  );
};
