import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '500px',
    '& .BaseTable': {
      fontSize: '14px !important',
    },
    '& .BaseTable__row-cell': {
      fontSize: '14px !important',
    },
    '& .BaseTable__row': {
      '& .cell': {
        visibility: 'visible',
        display: 'block',
      },
      '& .hover': {
        visibility: 'hidden',
      },
    },
    '& .BaseTable__row:hover': {
      '& .cell': {
        visibility: 'hidden',
        display: 'none',
      },
      '& .hover': {
        visibility: 'visible',
      },
    },
  },
});

export const Container: React.FC = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.container}>{children}</Paper>;
};
