import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getTableComponent from 'src/products/admin/pages/getTableComponent';
import schemas from 'src/products/admin/redux/tables/schemas';
import { withRouter } from 'react-router';

const UserAdminTableWrapper = (props) => {
  const Component = getTableComponent(schemas['customer_users']);

  return (
    <Component
      params={{
        ...props.match.params,
        id: props.organization_id,
      }}
    />
  );
};

UserAdminTableWrapper.propTypes = {
  params: PropTypes.object,
  organization_id: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    organization_id: state.user.profile.value.organizationId,
  };
};

export default connect(mapStateToProps, {})(withRouter(UserAdminTableWrapper));
