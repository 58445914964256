import { useRecoilValueLoadable } from 'recoil';
import { teamInfo } from '../recoil';

export const useTeamInfo = (id) => {
  const value = useRecoilValueLoadable(teamInfo({ id }));

  const isLoading = value.state === 'loading';

  return {
    isLoading,
    team: value.valueMaybe(),
  };
};
