import React from 'react';
import cx from 'classnames';
import getFormComponent from 'src/products/admin/pages/getFormComponent';
import { connect, useDispatch } from 'react-redux';
import schemas from 'src/products/admin/redux/forms/schemas';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import withStyles from '@material-ui/core/styles/withStyles';
import { push } from 'connected-react-router';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  body: {
    width: '100%',
    maxWidth: 980,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 25,
  },
  paper: {
    width: 220,
    marginRight: 20,
  },
  listItem: {
    cursor: 'pointer',
  },
  activeListItem: {
    background: '#eee',
  },
};

const icons = {
  'connectors-configuration': 'Settings-2',
};

const Configuration = ({ match: { params }, classes, organization_id }) => {
  const schemaName = params.schemaName || 'connectors-configuration';

  const FormComponent = getFormComponent(schemas[schemaName]);

  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <h2 style={{ color: '#000' }}>Application Settings</h2>

        <div className={classes.wrapper}>
          <Paper className={classes.paper}>
            {/* TODO: list should be dynamic */}
            <List>
              <ListItem
                onClick={() =>
                  dispatch(
                    push(
                      `/customerAdmin/users/${params.user_id}/configuration/connectors-configuration`,
                      {
                        params: {
                          id: params.user_id,
                          organization_id: organization_id,
                        },
                      }
                    )
                  )
                }
                className={cx(classes.listItem, {
                  [classes.activeListItem]:
                    'connectors-configuration' === schemaName,
                })}
              >
                <i
                  style={{ marginRight: 15 }}
                  className={`icon icon-${icons['connectors-configuration']}`}
                />{' '}
                Connectors
              </ListItem>
              <ListItem
                onClick={() =>
                  dispatch(
                    push(
                      `/customerAdmin/users/${params.user_id}/configuration/user-first-time-login`,
                      {
                        params: {
                          schemaName: 'test',
                          id: params.user_id,
                          organization_id: organization_id,
                        },
                      }
                    )
                  )
                }
                className={cx(classes.listItem, {
                  [classes.activeListItem]:
                    'user-first-time-login' === schemaName,
                })}
              >
                <i
                  style={{ marginRight: 15 }}
                  className={`icon icon-${icons['connectors-configuration']}`}
                />{' '}
                First Time Login
              </ListItem>
            </List>
          </Paper>

          <FormComponent
            params={{
              ...params,
              id: params.user_id,
              organization_id,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organization_id: state.user.profile.value.organizationId,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Configuration));
