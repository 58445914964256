import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Button from 'src/components/ButtonProgress';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

const useStyles = makeStyles({
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
  textField: {
    width: '100%',
  },
});

const CreateAPIKeyDialog = ({ open, onRequestClose, isCherreAdmin }) => {
  const entityName = 'API Key';
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [nickname, setNickname] = useState('');
  const [apiHasuraRole, setApiHasuraRole] = useState('');
  const [hasuraOrgName, setHasuraOrgName] = useState('');

  const handleClose = () => {
    setApiKey('');
    setNickname('');
    setApiHasuraRole('');
    setHasuraOrgName('');
    onRequestClose();
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);

      showSuccessPopupNotification('Success');
      handleClose();
    } catch (err) {
      showErrorPopupNotification('Could not copy to clipboard', 0);
    }
  };

  const handleClick = async (data) => {
    try {
      setLoading(true);

      const response = await fetch(`/api/v1/api-keys`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();

      if (response.status > 200) {
        showErrorPopupNotification(JSON.stringify(json), 0);
      } else {
        setApiKey(json.apiKey);
        showSuccessPopupNotification('Success');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle style={{ textTransform: 'capitalize' }}>
        {'Create'} {entityName}
      </DialogTitle>
      <DialogContent
        className={classNames(classes.modalContent, 'dialogContent')}
      >
        {apiKey ? (
          `Please copy this API key and keep it somewhere safe.
          You will not be able to view this key again once you exit this window.
          If you lose this key you will need to generate a new one.`
        ) : (
          <List>
            <ListItem>
              <TextField
                id='nickname'
                label='Nickname'
                value={nickname}
                className={classes.textField}
                onChange={(e) => setNickname(e.target.value)}
              />
            </ListItem>
            {isCherreAdmin && (
              <ListItem>
                <TextField
                  id='apiHasuraRole'
                  label='API Hasura Role Override'
                  value={apiHasuraRole}
                  className={classes.textField}
                  onChange={(e) => setApiHasuraRole(e.target.value)}
                />
              </ListItem>
            )}
            {isCherreAdmin && (
              <ListItem>
                <TextField
                  id='hasuraOrgName'
                  label='Hasura Org Name Override'
                  value={hasuraOrgName}
                  className={classes.textField}
                  onChange={(e) => setHasuraOrgName(e.target.value)}
                />
              </ListItem>
            )}
          </List>
        )}
        <p style={{ lineBreak: 'anywhere' }}>{apiKey}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          close
        </Button>
        {apiKey ? (
          <Button
            onClick={handleCopyToClipboard}
            color='primary'
            variant='contained'
          >
            copy to clipboard
          </Button>
        ) : (
          <Button
            onClick={() =>
              handleClick({
                nickname,
                apiHasuraRole,
                hasuraOrgName,
              })
            }
            color='primary'
            variant='contained'
            loading={loading}
          >
            submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CreateAPIKeyDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  isCherreAdmin: PropTypes.bool,
};

export default CreateAPIKeyDialog;
