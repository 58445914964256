import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import MoreVert from '@material-ui/icons/MoreVert';
import Tune from '@material-ui/icons/Tune';

const ActionMenuContext = createContext<{ close?: () => void }>({});

const useOnClickClose = (onClick: () => void) => {
  const { close } = useContext(ActionMenuContext);

  const onClickClose = useCallback(() => {
    close?.();
    onClick();
  }, [close, onClick]);

  return onClickClose;
};

export type MenuItemProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const ActionPermissionsMenuItem: React.VFC<MenuItemProps> = ({
  onClick,
  disabled = false,
}) => {
  const onClickClose = useOnClickClose(onClick);
  return (
    <MenuItem onClick={onClickClose} disabled={disabled}>
      <ListItemIcon>
        <Tune fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Permissions' />
    </MenuItem>
  );
};

export const ActionDeleteMenuItem: React.VFC<MenuItemProps> = ({ onClick }) => {
  const onClickClose = useOnClickClose(onClick);
  return (
    <MenuItem onClick={onClickClose}>
      <ListItemIcon>
        <Delete fontSize='small' color='primary' />
      </ListItemIcon>
      <ListItemText
        primary='Delete'
        primaryTypographyProps={{ color: 'primary' }}
      />
    </MenuItem>
  );
};

export const ActionEditMenuItem: React.VFC<MenuItemProps> = ({ onClick }) => {
  const onClickClose = useOnClickClose(onClick);
  return (
    <MenuItem onClick={onClickClose}>
      <ListItemIcon>
        <Edit fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Edit' />
    </MenuItem>
  );
};

export const ActionRenameMenuItem: React.VFC<MenuItemProps> = ({ onClick }) => {
  const onClickClose = useOnClickClose(onClick);
  return (
    <MenuItem onClick={onClickClose}>
      <ListItemIcon>
        <Edit fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Rename' />
    </MenuItem>
  );
};

export const ActionsMenu: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <IconButton
        aria-label='actions'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        <ActionMenuContext.Provider value={{ close: handleClose }}>
          {children}
        </ActionMenuContext.Provider>
      </Menu>
    </div>
  );
};
