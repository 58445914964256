import { TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import React from 'react';

export type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
};

export const SearchInput: React.VFC<SearchInputProps> = ({
  value,
  onChange,
}) => {
  return (
    <TextField
      style={{ width: 200 }}
      variant='outlined'
      value={value}
      size='small'
      placeholder='Search'
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: <Search />,
        style: { borderRadius: 6, backgroundColor: 'white' },
      }}
      data-testid='search_input'
    />
  );
};
