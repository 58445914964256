import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getFormComponent from 'src/products/admin/pages/getFormComponent';
import { getPermissions } from 'src/products/admin/redux/permissions/getActions';
import { getPermissionFields } from 'src/products/admin/redux/permissions/createPermissionFields';

import schemas from 'src/products/admin/redux/permissions/schemas';
import { withRouter } from 'react-router';
import { filterCherreAdminPermissions } from 'src/products/admin/utils/CherreAdmin';

class Permissions extends React.Component {
  //const Component = getPermissionsComponent();
  formData = Object.assign({ fields: [] }, schemas.customerUserPermissions);
  updatedparams = {
    id: this.props.match.params.user_id,
    ...this.props.match.params,
    organization_id: this.props.organization_id,
  };
  componentDidMount() {
    this.props.getPermissions(this.formData.id, this.updatedparams);
  }

  render() {
    const props = this.props;
    if (!props.organization_id) {
      return null;
    }

    const availablePermissions = filterCherreAdminPermissions(
      props.availablePermissions,
      props.organization_id,
      props.isCherreAdmin
    );

    const fields = getPermissionFields(availablePermissions);
    this.formData.fields = fields;

    const FormComponent = getFormComponent(this.formData);

    return (
      <FormComponent
        params={{
          id: true,
          formData: this.formData,
          ...props.match.params,
          organization_id: props.organization_id,
        }}
      />
    );
  }
}

Permissions.propTypes = {
  params: PropTypes.object,
  organization_id: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    availablePermissions: state.adminPanel.userPermissions.permissionListData,
    organization_id: state.user.profile.value.organizationId,
    isCherreAdmin: (state.user.profile.value.permissions || []).includes(
      'CherreAdmin'
    ),
  };
}

export default connect(mapStateToProps, {
  getPermissions,
  getPermissionFields,
})(withRouter(Permissions));
