import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import isNil from 'lodash/isNil';
import getTableComponent from 'src/products/admin/pages/getTableComponent';
import schemas from 'src/products/admin/redux/tables/schemas';
import { useParams, withRouter } from 'react-router-dom';
import CreateAPIKeyDialog from 'src/products/customerAdmin/pages/ApiKey/Create';
import { replace } from 'connected-react-router';

const UserApiKeysTableWrapper = (props) => {
  const Component = getTableComponent(schemas['customer_api_keys']);
  const { isCherreAdmin } = props;
  const params = useParams();

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    if (open) {
      setOpen(false);
      dispatch(replace('/customerAdmin/api-keys'));
    }
  };

  useEffect(() => {
    setOpen(!isNil(params?.action));
  }, [params]);

  return (
    <>
      <Component
        params={{
          ...props.match.params,
          id: props.organization_id,
        }}
      />
      <CreateAPIKeyDialog
        open={open}
        onRequestClose={handleClose}
        isCherreAdmin={isCherreAdmin}
      />
    </>
  );
};

UserApiKeysTableWrapper.propTypes = {
  params: PropTypes.object,
  organization_id: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    organization_id: state.user.profile.value.organizationId,
    isCherreAdmin: (state.user.profile.value.permissions || []).includes(
      'CherreAdmin'
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, {})(UserApiKeysTableWrapper)
);
