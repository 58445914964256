import { useCallback, useState } from 'react';
import { RecoilValueReadOnly, useRecoilValueLoadable } from 'recoil';
import { PaginatedOpts, Order } from '../recoil/types';

export const useTablePaginatedSelector = <T, E>(
  totalCountSelector: (param: E) => RecoilValueReadOnly<number>,
  paginatedSelector: (param: E & PaginatedOpts) => RecoilValueReadOnly<T[]>,
  params: E,
  key: keyof T
) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<null | Order>(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<T[keyof T][]>([]);

  const totalValue = useRecoilValueLoadable(totalCountSelector(params));

  const paginatedSelectorValue = useRecoilValueLoadable(
    paginatedSelector({ ...params, page, order, rowsPerPage })
  );

  const isLoading =
    totalValue.state === 'loading' ||
    paginatedSelectorValue.state === 'loading';
  const totalCount = totalValue.valueMaybe() || 0;
  const items = paginatedSelectorValue.valueMaybe() || [];

  const onSelectAllChange = useCallback(
    (checked) => {
      setIsAllRowsSelected(checked);
      if (checked) {
        setSelectedRowKeys(items.map((i) => i[key]));
      } else {
        setSelectedRowKeys([]);
      }
    },
    [setIsAllRowsSelected, items]
  );

  const onSelect = useCallback(
    ({ rowData, selected }) => {
      if (selected) {
        setSelectedRowKeys((s) => [...s, rowData[key]]);
      } else {
        setIsAllRowsSelected(false);
        setSelectedRowKeys((s) => [...s.filter((i) => i !== rowData[key])]);
      }
    },
    [setIsAllRowsSelected, setSelectedRowKeys]
  );

  const onChangePage = useCallback((e) => {
    setPage(e);
  }, []);

  const onChangeOrder = useCallback(
    (e) => {
      setOrder(e);
    },
    [setOrder]
  );

  const onChangeRowsPerPage = useCallback((e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
  }, []);

  return {
    isLoading,
    totalCount,
    rowsPerPage,
    page,
    hasNextPage: (page + 1) * rowsPerPage < totalCount,
    hasPreviousPage: page > 0,
    order,
    items,
    isAllRowsSelected,
    selectedRowKeys,
    onChangePage,
    onChangeOrder,
    onChangeRowsPerPage,
    onSelectAllChange,
    onSelect,
    setSelectedRowKeys,
    setIsAllRowsSelected,
  };
};
