import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    height: '500px',
    alignItems: 'center',
    justifyItems: 'center',
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridGap: '8px',
  },
  actions: {
    paddingTop: '27px',
  },
});

export const Hero: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography variant='h4'>{title}</Typography>
        <Typography>{subtitle}</Typography>
        <Box className={classes.actions}>{children}</Box>
      </Box>
    </Box>
  );
};
