import { Avatar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { getNameInitials } from 'src/utils/getNameInitials';

const useStyles = makeStyles<Theme>((theme) => ({
  avatar: {
    width: 36,
    height: 36,
    fontSize: 12,
    marginRight: 11,
    fontWeight: 500,
    lineHeight: 1.13,
    backgroundColor: theme.palette.secondary.light,
  },
}));

export const AvatarAndName: React.VFC<{ name: string }> = ({ name }) => {
  const classes = useStyles();

  return (
    <>
      <Avatar className={classes.avatar}>{getNameInitials(name)}</Avatar>
      {name}
    </>
  );
};
