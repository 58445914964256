import React, { useMemo, useState } from 'react';
import {
  useSnackbarCherre,
  useTeamCreateOrEdit,
} from 'src/products/core-prospect/hooks';
import { Container } from './components/Container';
import PagedTable from 'src/components/PagedTable';
import { Button, Divider, Typography } from '@material-ui/core';
import { SearchInput } from './components/SearchInput';
import { useTablePaginatedSelector } from './hooks/useTablePaginatedSelector';
import { teamsList, teamsListPaginated, teamsTotalCount } from './recoil';
import { getMaskedValue } from 'src/utils/mask';
import { Hero } from './components/Hero';
import {
  ActionDeleteMenuItem,
  ActionRenameMenuItem,
  ActionsMenu,
} from './components/ActionsMenu';
import axios from 'axios';
import { useRecoilCallback } from 'recoil';
import { Link } from 'react-router-dom';
import { useShowModal } from 'src/hooks/useShowModal';
import { Page } from 'src/components/Page';
import { Header } from 'src/components/Header';

const TeamsList = () => {
  const teamCreateOrEditModal = useTeamCreateOrEdit();

  const [searchInput, setSearchInput] = useState('');
  const { enqueueSnackbar } = useSnackbarCherre();

  const showConfirmDialog = useShowModal('CONFIRM');

  const {
    isLoading,
    totalCount,
    rowsPerPage,
    page,
    hasNextPage,
    hasPreviousPage,
    order,
    items,
    isAllRowsSelected,
    selectedRowKeys,
    onChangePage,
    onChangeOrder,
    onChangeRowsPerPage,
    onSelectAllChange,
    onSelect,
  } = useTablePaginatedSelector(
    teamsTotalCount,
    teamsListPaginated,
    {
      name: searchInput,
    },
    'id'
  );

  const deleteGroup = useRecoilCallback(
    ({ refresh }) =>
      async (id: number) => {
        try {
          await axios.delete(`/api/v1/teams/${id}`);

          enqueueSnackbar(`Group deleted with success`, {
            variant: 'success',
          });

          refresh(teamsList);
        } catch (e) {
          console.error(e);

          enqueueSnackbar('Failed to delete this group', {
            variant: 'error',
          });

          throw e;
        }
      },
    []
  );

  const columns = useMemo(
    () => [
      {
        label: 'Name',
        dataKey: 'name',
        isSortable: true,
        width: 0,
        flexGrow: 2,
        cellRenderer: ({ cellData, rowData }) => {
          return (
            <Link
              to={`/customerAdmin/groups/${rowData['id']}`}
              style={{ fontWeight: 'bold' }}
            >
              {cellData}
            </Link>
          );
        },
      },
      {
        label: 'Users',
        dataKey: 'membersCount',
        isSortable: true,
        width: 0,
        flexGrow: 1,
      },
      {
        label: 'Created',
        dataKey: 'createdAt',
        isSortable: true,
        width: 100,
        flexGrow: 1,
        cellRenderer: ({ cellData }) => getMaskedValue(cellData, 'date'),
      },
      {
        label: '',
        dataKey: '',
        width: 70,
        flexGrow: 0,
        flexShrink: 0,
        cellRenderer: ({ rowData }) => (
          <ActionsMenu>
            <ActionRenameMenuItem
              onClick={() => {
                teamCreateOrEditModal(rowData.id, rowData.name);
              }}
            />
            <Divider />
            <ActionDeleteMenuItem
              onClick={() => {
                showConfirmDialog({
                  title: 'Delete Group',
                  message: (
                    <>
                      <Typography>
                        You're about to delete the{' '}
                        <strong>{rowData.name}</strong> group.
                      </Typography>
                      <Typography>
                        Once deleted you won't be able to access this group
                        anymore.
                      </Typography>
                    </>
                  ),
                  okText: 'Delete',
                  onClickOk: () => deleteGroup(rowData.id),
                });
              }}
            />
          </ActionsMenu>
        ),
      },
    ],
    []
  );

  const addGroupButton = (
    <Button
      variant='contained'
      color='primary'
      onClick={() => teamCreateOrEditModal()}
      data-testid='btn_add_group'
    >
      Add Group
    </Button>
  );

  return (
    <Page>
      <Header
        title='Groups'
        subtitle={[
          `Create and manage groups of specific people within our organization.`,
          `Share property lists with groups that you've created or where you are a member.`,
        ]}
      >
        {addGroupButton}
        <SearchInput value={searchInput} onChange={setSearchInput} />
      </Header>
      <Container>
        {isLoading || items.length > 0 ? (
          <PagedTable
            stickyLeft={0}
            isAllRowsSelected={isAllRowsSelected}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            page={page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSelect={onSelect}
            onSelectAllChange={onSelectAllChange}
            onChangePage={onChangePage}
            onChangeOrder={onChangeOrder}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            orderBy={order?.field}
            orderByDirection={order?.direction}
            columns={columns}
            items={items}
            isLoading={isLoading}
            emptyText=''
            rowKey={'id'}
            selectedRowKeys={selectedRowKeys}
            fixed={false}
            selection={false}
          />
        ) : (
          <Hero
            title={searchInput ? 'No Groups Found' : 'No Groups Created'}
            subtitle={
              searchInput
                ? 'There are no groups matching you search'
                : 'There are currently no groups.'
            }
          >
            {addGroupButton}
          </Hero>
        )}
      </Container>
    </Page>
  );
};

export default TeamsList;
