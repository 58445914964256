import axios from 'axios';
import { orderBy } from 'lodash';
import { selector, selectorFamily } from 'recoil';
import * as T from './types';

export const teamsList = selector({
  key: 'CORE-EXPLORE/TEAM/ALL',
  get: async () => {
    const response = await axios.get<T.TeamListReturn>(`/api/v1/teams`);
    return response.data;
  },
});

export const teamsTotalCount = selectorFamily({
  key: 'CORE-EXPLORE/TEAM/COUNT',
  get:
    () =>
    async ({ get }) => {
      const teams = await get(teamsList);
      return teams.length;
    },
});

export const teamInfo = selectorFamily<T.TeamInfoReturn, { id: number }>({
  key: 'CORE-EXPLORE/TEAM/INFO',
  get:
    ({ id }) =>
    async () => {
      const response = await axios.get<T.TeamInfoReturn>(`/api/v1/teams/${id}`);
      return response.data;
    },
});

export const teamMembersTotalCount = selectorFamily<number, { id: number }>({
  key: 'CORE-EXPLORE/TEAM/MEMBERS/COUNT',
  get:
    ({ id }) =>
    async ({ get }) => {
      const members = await get(teamInfo({ id }));
      return members.members.length;
    },
});

export const teamsListPaginated = selectorFamily<
  T.TeamListReturn,
  { name: string } & T.PaginatedOpts
>({
  key: 'CORE-EXPLORE/TEAM/ALL/PAGINATED',
  get:
    ({ name, page, order, rowsPerPage }) =>
    async ({ get }) => {
      const teams = await get(teamsList);
      const teamsFiltered = teams.filter(
        (t) =>
          !name ||
          t.name.toLocaleLowerCase().startsWith(name.toLocaleLowerCase())
      );
      const teamsOrder = order
        ? orderBy(teamsFiltered, [order.field], [order.direction])
        : teamsFiltered;
      return teamsOrder.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    },
});

export const teamMembersPaginated = selectorFamily<
  T.TeamInfoReturn['members'],
  { id: number; name: string } & T.PaginatedOpts
>({
  key: 'CORE-EXPLORE/TEAM/MEMBERS/PAGINATED',
  get:
    ({ name, id, page, order, rowsPerPage }) =>
    async ({ get }) => {
      const members = await get(teamInfo({ id }));

      const membersFiltered = members.members.filter(
        (t) =>
          !name ||
          (t.firstName || '')
            .toLocaleLowerCase()
            .startsWith(name.toLocaleLowerCase()) ||
          (t.lastName || '')
            .toLocaleLowerCase()
            .startsWith(name.toLocaleLowerCase())
      );

      const membersOrder = order
        ? orderBy(membersFiltered, [order.field], [order.direction])
        : membersFiltered;

      return membersOrder.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    },
});
