import React, { useCallback, useMemo, useState } from 'react';
import { Container } from './components/Container';
import PagedTable from 'src/components/PagedTable';
import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { SearchInput } from './components/SearchInput';
import {
  teamInfo,
  teamMembersPaginated,
  teamMembersTotalCount,
} from './recoil';
import { useTablePaginatedSelector } from './hooks/useTablePaginatedSelector';
import {
  useSnackbarCherre,
  useTeamAddMembers,
} from 'src/products/core-prospect/hooks';
import { useTeamInfo } from './hooks/useTeamInfo';
import { Hero } from './components/Hero';
import { AvatarAndName } from './components/AvatarAndName';
import axios from 'axios';
import { useRecoilCallback } from 'recoil';
import { useShowModal } from 'src/hooks/useShowModal';
import { CellWithHoverActions } from './components/CellWithHoverActions';
import Cancel from '@material-ui/icons/Cancel';
import { Page } from 'src/components/Page';
import { Header } from 'src/components/Header';

const TeamMembers = (props) => {
  const id = Number(props.match.params.group_id) || 0;

  const [searchInput, setSearchInput] = useState('');
  const teamAddMembersModal = useTeamAddMembers();
  const { enqueueSnackbar } = useSnackbarCherre();
  const showConfirmDialog = useShowModal('CONFIRM');

  const { team } = useTeamInfo(id);
  const currentMembers = useMemo(
    () => team?.members.map((m) => m.id) || [],
    [team]
  );

  const {
    isLoading: isLoadingTable,
    totalCount,
    rowsPerPage,
    page,
    hasNextPage,
    hasPreviousPage,
    order,
    items,
    isAllRowsSelected,
    selectedRowKeys,
    onChangePage,
    onChangeOrder,
    onChangeRowsPerPage,
    onSelectAllChange,
    onSelect,
  } = useTablePaginatedSelector(
    teamMembersTotalCount,
    teamMembersPaginated,
    {
      id,
      name: searchInput,
    },
    'id'
  );

  const deleteMember = useRecoilCallback(
    ({ refresh }) =>
      async (userId: number) => {
        if (!team) {
          return;
        }

        try {
          await axios.post(`/api/v1/teams/${team.id}/members`, {
            memberIds: currentMembers.filter((m) => userId !== m),
          });

          enqueueSnackbar(`Member deleted with success`, {
            variant: 'success',
          });

          refresh(teamInfo({ id }));
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Failed to remove member', {
            variant: 'error',
          });
          throw e;
        }
      },
    [team, currentMembers]
  );

  const onClickDelete = useCallback(
    (userId: number, name: string) => {
      showConfirmDialog({
        title: 'Remove Group Member',
        message: (
          <>
            <Typography>
              Are you sure you want to remove <strong>{name}</strong> from the{' '}
              <strong>{team?.name}</strong> group?
            </Typography>
          </>
        ),
        okText: 'Remove',
        onClickOk: () => deleteMember(userId),
      });
    },
    [showConfirmDialog, deleteMember, team]
  );

  const columns = useMemo(
    () => [
      {
        label: 'Name',
        dataKey: 'id',
        isSortable: true,
        width: 0,
        flexGrow: 2,
        cellRenderer: ({ rowData }) => (
          <AvatarAndName name={`${rowData.firstName} ${rowData.lastName}`} />
        ),
      },
      {
        label: 'Email',
        dataKey: 'email',
        isSortable: true,
        width: 0,
        flexGrow: 2,
      },
      {
        label: 'Last active',
        dataKey: 'last_active',
        isSortable: true,
        width: 0,
        flexGrow: 1,
        cellRenderer: ({ cellData, rowData }) => (
          <CellWithHoverActions cell={cellData}>
            <Tooltip title={`Remove`}>
              <IconButton
                onClick={() =>
                  onClickDelete(
                    rowData.id,
                    `${rowData.firstName} ${rowData.lastName}`
                  )
                }
                size='small'
                data-testid='delete_btn'
              >
                <Cancel
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  color='primary'
                />
              </IconButton>
            </Tooltip>
          </CellWithHoverActions>
        ),
      },
    ],
    [onClickDelete]
  );

  if (!team) {
    return null;
  }

  const addMembersButton = (
    <Button
      variant='contained'
      color='primary'
      onClick={() => teamAddMembersModal(id, currentMembers)}
    >
      Add Members
    </Button>
  );

  return (
    <Page>
      <Header
        title={team?.name}
        subtitle={`${team?.members.length} members`}
        backLink='/customerAdmin/groups'
      >
        {addMembersButton}
        <SearchInput value={searchInput} onChange={setSearchInput} />
      </Header>
      <Container>
        {isLoadingTable || items.length > 0 ? (
          <PagedTable
            stickyLeft={0}
            isAllRowsSelected={isAllRowsSelected}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            page={page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSelect={onSelect}
            onSelectAllChange={onSelectAllChange}
            onChangePage={onChangePage}
            onChangeOrder={onChangeOrder}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            orderBy={order?.field}
            orderByDirection={order?.direction}
            columns={columns}
            items={items}
            isLoading={isLoadingTable}
            emptyText=''
            rowKey={'id'}
            selectedRowKeys={selectedRowKeys}
            fixed={false}
            selection={false}
          />
        ) : (
          <Hero
            title={searchInput ? 'No members found' : 'No members yet'}
            subtitle={
              searchInput
                ? 'There are no members matching you search'
                : `There're no members in the group yet.`
            }
          >
            {addMembersButton}
          </Hero>
        )}
      </Container>
    </Page>
  );
};

export default TeamMembers;
